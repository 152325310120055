/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Icon,
  GridColumn,
  Divider,
  List,
  ListItem,
} from "semantic-ui-react";
import "./index.scss";

const position = "top-right";
const autoClose = 5000;

const propTypes = {
  /** array of acks objects which have at least a display_message attribute */
  acks: PropTypes.arrayOf(PropTypes.object),
};
/**
 * The AckMessenger takes an array of ack's and shows the new one with toastify with each addition
 * @param {Array} acks
 * @returns ToastContainer
 */
const AckMessenger = ({ acks }) => {
  const [lastNotification, setLastNotification] = useState();

  /** watch acks messages to show toastify message */
  useEffect(() => {
    /** the first ack is the new one */
    if (acks && acks.length) {
      let last = acks[0];
      setLastNotification(last);
    }
  }, [acks]);

  useEffect(() => {
    if (lastNotification) {
      let message =
        lastNotification.message || lastNotification.display_message;
      let icon, iconColor;
      console.log("lastNotification", lastNotification);
      if (lastNotification.status_code !== undefined) {
        icon =
          lastNotification.status_code === 401 ||
          lastNotification.status_code === 402 ||
          lastNotification.status_code === 403 ||
          lastNotification.status_code === 400 ||
          lastNotification.status_code === 500 ||
          lastNotification.status_code === 422 ||
          lastNotification.status_code === 409
            ? "close"
            : "check";
        iconColor =
          lastNotification.status_code === 401 ||
          lastNotification.status_code === 402 ||
          lastNotification.status_code === 403 ||
          lastNotification.status_code === 400 ||
          lastNotification.status_code === 500 ||
          lastNotification.status_code === 422 ||
          lastNotification.status_code === 409
            ? "#db2828"
            : "#21ba45";
      } else {
        icon =
          lastNotification.statusCode === 401 ||
          lastNotification.statusCode === 402 ||
          lastNotification.statusCode === 403 ||
          lastNotification.statusCode === 400 ||
          lastNotification.statusCode === 500 ||
          lastNotification.statusCode === 422 ||
          lastNotification.statusCode === 409
            ? "close"
            : "check";
        iconColor =
          lastNotification.statusCode === 401 ||
          lastNotification.statusCode === 402 ||
          lastNotification.statusCode === 403 ||
          lastNotification.statusCode === 400 ||
          lastNotification.statusCode === 500 ||
          lastNotification.statusCode === 422 ||
          lastNotification.statusCode === 409
            ? "#db2828"
            : "#21ba45";
      }

      let description = lastNotification.request_parameter;
      let type = "info"; //warning ||
      toast[type](
        <Grid style={{ paddingBottom: "10px" }}>
          <GridColumn
            width="16"
            verticalAlign="middle"
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            Notification
          </GridColumn>
          <GridColumn width="16" style={{ padding: "0px" }}>
            <Divider fitted style={{ padding: "0px" }} />
          </GridColumn>
          <GridColumn
            verticalAlign="middle"
            textAlign="center"
            width="3"
            className="icon"
          >
            <Icon name={icon} size="big" style={{ color: iconColor }} />
          </GridColumn>
          <GridColumn
            verticalAlign="middle"
            width="13"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            <List verticalAlign="middle">
              <ListItem>{message}</ListItem>
              <ListItem>{description}</ListItem>
            </List>
          </GridColumn>
        </Grid>,
        {
          position: position,
          autoClose: autoClose,
          pauseOnHover: true,
          closeOnClick: true,
          onClose: setLastNotification(undefined),
        }
      );
    }
  }, [lastNotification]);

  return <ToastContainer />;
};
AckMessenger.propTypes = propTypes;
export { AckMessenger };
